import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';

const Form3 = () => {

	const example3Ref = useNav('Bilingual');

	return (
		<section ref={example3Ref} id='example3Container' className="form_section">
			<div className="div_desc">
				<h2>Bilingual forme</h2>
				<p>Demonstration of the ... </p>
				<p>Examples: </p>
			</div>
			<div className="div_form">
				TODO !
			</div>
		</section>
	);
};

export default Form3;

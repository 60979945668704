import React, { useEffect } from 'react';
import './App.css';
import Forms from './forms';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import logo from "./logo.svg";
import video from "./oci_1.mov"

export default function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/forms" element={<Forms />} />
          </Routes>
      </BrowserRouter>
  );
}


export function Home() {

/*
    const [ page, setPage ] = useState("loading");
    useEffect(() => {(async () => {
    const res = await fetch(`http://dev.mondonomo.com/api/autocomplete?name=davor`,
                   {
                      //mode: 'no-cors',
                      headers: {
                          'Content-type': 'application/json',
                          'Authorization': 'Token 309c661a4ß047ca231856e296f5875f5f1e017541',
                      },
                  }).catch(err => setPage(err.message));
    if (!res) return;
    const txt = await res.text();
    setPage(txt);
  })();}, []);
*/

    useEffect(() => {
        document.title = 'SmartBase powered by Mondonomo';
      }, []);


  return (
    <div  className="showcase">
          <div className="video-container">
              <video className="video" src={video} height="100vh" autoPlay muted loop></video>
              <div className="overlay"></div>
          </div>

          <div className="content">
              <div className="logo">
                  <img className="svg" src={logo} alt={"SmartBase logo"} />
                      <span>smartbase.ai</span>
                   </div>
              <div className="heading">
                  <p className="h1">Bringing the world <br /> together by
                      <span className="spanTag"> names</span>
                  </p>
                  <p className="h3">Powered by
                      <a className="App-link"
                        href="https://mondonomo.com"
                        target="_blank"
                        rel="noopener noreferrer"> Mondonomo</a></p>
                  <Link to="/forms"  className="btn">Beta preview
                  </Link>
              </div>
          </div>
    </div>
  );
}





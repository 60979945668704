import React, {useEffect} from 'react';
import { Form1, Form2, Form3 } from './';


function Main() {
	useEffect(() => {
		document.title = 'SmartBase powered by Mondonomo';
	}, []);

	return (
		<main>
			<Form1/>
			<Form2/>
			<Form3/>
		</main>
	);
}

export default Main;

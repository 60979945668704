import React from 'react';
import './forms.css';
import AsyncSelect from 'react-select/async-creatable';
import { components } from 'react-select';


function SmartField(props) {

	const MIN_INPUT_LENGTH = 2;
    const message_init = (props.data_type === "fn") ? "Please enter your first name in your native language"
        : (props.data_type === "ln") ? "Please enter your last name in your native language"
        : (props.data_type === "tit") ? "Please enter your personal title in your native language"
        : (props.data_type === "occup") ? "Please enter occupation in your native language"
        : (props.data_type === "locs") ? "Please enter location in your native language"
        : 'Please neter value';

    const label = (props.data_type === "fn") ? "Forename"
        : (props.data_type === "ln") ? "Surname"
        : (props.data_type === "tit") ? "Title"
        : (props.data_type === "occup") ? "Occupation"
        : (props.data_type === "locs") ? "Location (city/region/country)"
        : 'Please neter value';

    const message_short = "Please continue typing "
    const message_not_found = "Name if not found TODO"


	function getNames(input, callback) {
		if (input.length < MIN_INPUT_LENGTH) {
			callback([]);
		} else
		{
			const base_url = 'https://dev.mondonomo.com'
            //const base_url = 'http://localhost:8000'
            const url = props.data_type === 'occup' ?`${base_url}/api/autocompleteoccup?name=${input}`
				: props.data_type === 'locs' ?`${base_url}/api/autocompletelocs?name=${input}`
                :`${base_url}/api/autocomplete?name=${input}&type=${props.data_type}`
			return fetch(url,
				{
					headers: {
						'Authorization': 'Token 309c661a4047ca231856e296f5875f5f1e017541'
					},
					'Access-Control-Allow-Origin': '*',
				}).then(res => res.json())
				.then(res => {
					return res.items
				});
		}
		}

	const promiseOptions = (inputValue: string, callback) =>
	  new Promise ((resolve) => {
		setTimeout(() => {
		  resolve(getNames(inputValue, callback));
		}, 200);
	  });

    const noOptionsMessage = (input) => {
		if (input.inputValue.length === 0) {
			return message_init;
		} else if (input.inputValue.length < 3) {
			return message_short;
		} else {
			return message_not_found;
		}
	}

    const Option = props => {
    return (
        <components.Option {...props} >
            <div style={{color:  props.data.color}}>
                {props.data.label}
                </div>
            <br/>
            <small style={{color: 'gray'}}>
                {props.data.desc}
            </small>
        </components.Option>
    )};

	const styles: { [key: string]: CSSProperties } = {
		control: base => ({
  			...base,
			fontSize: "1.5rem"}),
		menu: base => ({
  			...base,
			fontSize: "1.5rem"}),
		blockquote: {
		  fontStyle: 'italic',
		  fontSize: '.75rem',
		  margin: '1rem 0',
		},
		label: {
		  fontSize: '.75rem',
		  fontWeight: 'bold',
		  lineHeight: 2,
		},
  };


    return (<div>
		      <label style={styles.label} htmlFor={props.id}>
				  {label}
		      </label>
            <AsyncSelect
                //optionRenderer={renderOption}
                components={{ Option }}
                id={props.id}
                label={label}
                isClearable={true} cacheOptions
                loadOptions={promiseOptions} defaultOptions
                noOptionsMessage={noOptionsMessage}
                styles={styles}
				searchInput={{ autocomplete: 'new-password', autofill: 'off' }}

                placeholder={label} className="Input"/>
		</div>
    );
}

export default SmartField;
import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import SmartField from './Fields'

const Form1 = () => {

	const example1Ref = useNav('Autocomplete');


	return (
		<section ref={example1Ref} id="example1Container" className="form_section">
				<div className="div_desc">
					<h2>Multilingual structured inputs (early beta)</h2>
					<p>An example of registration form with the separated smart multilingual fields for name parts and other data.
					</p>
					<h3>TO DO</h3>
					<ul>
						<li>Improve UX (creating new items, validation) </li>
						<li>Finish neural models for semantic search & suggestions</li>
						<li>Clean datasets (xoxoxo ...)</li>
						<li>...</li>
					</ul>
				</div>
				<form className="div_form" autoComplete="off">
					<SmartField data_type="tit" id="tit" />

					<SmartField data_type="fn" id="fn" />
					<SmartField data_type="ln" id="ln" />

					<SmartField data_type="occup" id="occup" />
					<SmartField data_type="locs" id="locs" />
				</form>
		</section>
	);
};

export default Form1;

import React, { useState } from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';


const Form2 = () => {

	const example2Ref = useNav('Full name');
	const [message, setMessage] = useState('');

    const [data, setData] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [err, setErr] = useState('');


	const ActionLink = (props) => {
	  const handleActionClick = (e) => {
		e.preventDefault();
   		setMessage(props.label)
	    };

		return (<a href="#" onClick={handleActionClick}>
			{props.label}
		  </a>
		)
	}

	const handleChange = event => {
			setMessage(event.target.value);
		// setTimeout(() => {
		// 	setMessage(event.target.value);
		// 	}, 2000);
	};

	const handleClick = async () => {
		setIsLoading(true);
		const url = 'https://dev.mondonomo.com/api/pnu?fullname='+
			encodeURIComponent(message)+'&return_html=true';
		console.log(url)
		try {
		  const response = await fetch(url, {
				headers: {'Authorization': 'Token 309c661a4047ca231856e296f5875f5f1e017541'},
				'Access-Control-Allow-Origin': '*',
			});

		  if (!response.ok) {
			throw new Error(`Error! status: ${response.status}`);
		  }

		  const result = await response.json();

		  //console.log('result is: ', JSON.stringify(result, null, 4));

		  setData(result);
		} catch (err) {
		  setErr(err.message);
		} finally {
		  setIsLoading(false);
		}
	  };

	function PNUResult() {
		if (!data) {
			return (<div>
				Enter a name and click on "Analyse" to se a demonstration of name understanding engine
			    <br />Try:
					<ActionLink label="President Joe Biden"/>,
				<ActionLink label="อับดุลฮาฟิสบือราเฮง"/>,
				<ActionLink label="Фёдор Михайлович Достоевский"/>,


			</div>)
			}
		else if (isLoading) {
			return '<div></div>'
		}
		else if (err) {
			return err
		}
		else
			return (
				<div><div dangerouslySetInnerHTML={data}></div> </div>
				);
		}

	const handleFocus = (event) => {
		   event.preventDefault();
		   event.target.select();
	}

	const handleKeydown = e => {
		  //it triggers by pressing the enter key
		if (e.keyCode === 13) {
		  handleClick();
		}
	  };

	return (
		<section ref={example2Ref}  id='example2Container' className="form_section href_annchor">

			<div className="div_desc">
				<h2>Full name smart field (early beta) </h2>
				<p>Demonstration of the Mondonomo <i>proper name understanding engine</i>. User can
					enter her/his full name, including personal tiles and all name parts, in their native language. </p>
				<h3>TO DO</h3>
				<ul>
					<li>Finish neural models for name understanding, improve Bayesian modeling of the data driven model, handle unknown names, and create ensemble models</li>
					<li>Improve UX & end user experience (chatbot for clarification of non-recognised names) </li>
					<li>Finish cultural sensitive language generation (formal/informal salutations, ...)</li>
					<li>Security features (fake names, celebrities, KYC...) </li>
					<li>...</li>
					</ul>
			</div>
			<div className="div_form">
				<input type="text"
					   name="new_name"
					   className="fullNameInput"
					   placeholder="Enter a full name in any language"
					   onChange={handleChange}
					   onMouseDown={handleFocus}
					   onKeyDown={handleKeydown}
					   defaultValue={message}
				/>
				<button onClick={handleClick}  className="fullNameButton"  >Analyse!</button>
					<div className="result_box">
						<PNUResult  />
					</div>
			</div>
		</section>
	);
};

export default Form2;
